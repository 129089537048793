.header-container {
    -webkit-app-region: drag;
    padding: 10px 0;
    z-index: 100;
    width: 100%;
    position: fixed;
    background: transparent
}

.header-shadow {
    background: #fff9f4;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.09);
}

.hide-header {
    opacity: 0;
    margin-top: -70px;
}

.header {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.header .logo {
    height: 50px;
    z-index: 1;
}

/* Header: Left */

.header .header-left {
    flex: 1;
    margin: 0 10px;
}

.header .header-left-content {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.header .header-left-content .round-button-container {
    height: 48px;
}

.header .header-left-content button {
    margin-right: 8px;
}

.header .header-left-content button .anticon {
    font-size: 18px;
    color: gray;
}

.header .bag-button, .header .bag-button:hover {
    background: #eceffa;
    border-color: #d8def5;
}

.header .store-button, .header .store-button:hover {
    background: #ffeee8;
    border-color: #ffddd2;
}

/* Header: Right */

.header .header-right {
    flex: 1;
    margin: 0 10px;
    text-align: right;
}

.header .header-right-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.header .number-container {
    display: flex;
    align-items: center;    
    max-width: 90px;
    width: 100%;
}

.header .number {
    flex: 1;
    text-align: left;
    padding-left: 10px;
    font-size: 17px;
    font-weight: 500;
}

.header .options-button {
    height: 20px;
    cursor: pointer;
}

.header .login-button {
    width: 100px;
    margin-left: auto;
}

.header .show-in-mobile {
    display: none;
}

@media screen and (max-width: 480px) {
    .header .hide-in-mobile {
        display: none;
    }

    .header .show-in-mobile {
        display: flex;
    }
}

/* Collection */

.collections { 
    padding-top: 10px;
    display: flex;
    justify-content: center;
}

.collections .items {
    display: flex;
    flex-wrap: wrap;
    width: 240px;
}

.collections .item {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.collections .item-picture {
    background: #fff8f3;
    padding: 3px;
    border-radius: 4px;
    width: 50px;
    height: 50px;
    margin: 5px;
}

.collections .items-label {
    font-weight: 700;
    color: #aaa;
    text-align: center;
    margin: 15px;
    width: 100%;
}

.collections-popover {
    width: 275px;
}

/* Options */

.header-option {
    width: 150px;
}

/* Preview */

.preview-banner {
    position: fixed;
    background: #fff9f4;
    width: 100%;
    height: 37px;
    z-index: 3;
}

.preview-banner .ant-alert {
    text-align: center;
    font-weight: 600;
    color: #efa105;
}