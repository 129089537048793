body {
    background: #fff9f4;
}

.main {
    background: #fff9f4;
    padding-top: 70px;
}

.mobile-main {
    background: #fff9f4;
}

#root, .app, .ant-layout {
    height: 100%;
}

.not-found-state, .error-state {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-height: 300px;
}

.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%; 
    min-height: 300px;
}

.loading.big-spinner {
    margin-left: -25px;
    margin-top: -25px;
}

.word-logo {
    margin-top: -15px;
    opacity: 0.2;
}

.logo {
    height: 50px;
}

/* Triangle */

.triangle {
    width: 15px;
    height: 15px;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 15px solid seashell;
    margin-left: 40px;
    margin-top: 10px;
}

/* Button */

.button {
    width: 100%;
    outline: none;
    cursor: pointer;
    font-size: 15px;
    font-weight: 500;
    border-width: 1px;
    border-style: solid;
    transition: all 0.2s;
    margin-top: -3px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.button:active, .button:disabled {
    margin-top: 0px;
    margin-bottom: -3px;
}

.button:disabled {
    opacity: 0.8;
    cursor: not-allowed;
}

.button.small {
    height: 30px;
    border-radius: 15px;
    font-size: 12px;
    font-weight: 500;
    color: darkgray;
}
.button.normal {
    height: 40px;
    border-radius: 20px;
    font-size: 15px;
}
.button.large {
    height: 50px;
    border-radius: 25px;
    font-size: 16px;
    padding: 0 25px;
    font-weight: 500;
}
.button.normal-circle {
    height: 40px;
    width: 40px;
    border-radius: 20px;
}
.button.large-circle {
    height: 50px;
    width: 50px;
    border-radius: 25px;
}

.button.primary {
    background: #ba5f34;
    color: white;
    border-color: sienna;
    box-shadow: 0px 5px 0px 0px sienna;
}
.button.primary:hover {
    background: #b45c33
}
.button.primary:active, .button.primary:disabled {
    box-shadow: 0px 2px 0px 0px sienna;
}

.button.white {
    background: white;
    border-color: #e9e9e9;
    box-shadow: 0px 5px 0px 0px #e9e9e9;
    color: rgba(0, 0, 0, 0.65);
}
.button.white:hover {
    background: #fafafa
}
.button.white:active, .button.white:disabled {
    box-shadow: 0px 2px 0px 0px #e9e9e9;
}

.button.black {
    background: #888;
    color: white;
    border-color: #666;
    box-shadow: 0px 5px 0px 0px #666;
}
.button.black:hover {
    background: #8e8e8e;
}
.button.black:active, .button.black:disabled {
    box-shadow: 0px 2px 0px 0px #666;
}

.button.green {
    background: #34a884;
    color: white;
    border-color: #1e9f77;
    box-shadow: 0px 5px 0px 0px #1e9f77;
}
.button.green:active, .button.green:disabled {
    box-shadow: 0px 2px 0px 0px #1e9f77;
}

.button.flat {
    background: #f5f5f5;
    border: none;
    width: 100px;
}

.button .anticon-loading {
    font-size: 22px;
}

/* Item popover */

.item-description .ant-popover-inner {
    background: rgba(88,88,88, 0.95);
}

.item-description .ant-popover-arrow {
    border-color: rgba(88,88,88, 0.95) !important;
}

.item-description .ant-popover-title {
    color: white;
    border-bottom: none;
    padding-top: 10px;
}

.item-description .ant-popover-inner-content {
    color: Gainsboro;
    padding-top: 0px;
}

/* Feedback Box */

.feedback-box {
    margin: 25px auto;
}

.feedback-box textarea {
    resize: none;
    border: none;
    margin-bottom: 20px;
    font-size: 19px;
    padding: 12px 15px;
    border-radius: 16px;
    box-shadow: 0 4px 0px #f1f1f1;
}

.feedback-box .thank-you {
    padding-bottom: 10px;
    text-align: center;
}

.feedback-box .thank-you p {
    font-weight: 600;
    color: saddlebrown;
    margin-bottom: 21px;
    font-size: 15px;
}

.feedback-box .thank-you span {
    font-size: 50px;
}

/* override Ant Design with the following syntax: .yourClass.antdClass */

.error.ant-alert {
    margin-bottom: 20px;
}

.ant-card {
    border-radius: 4px !important;
}

.ant-modal-mask {
    background-color: rgba(255,249,244, 0.7) !important;
}

a:focus {
    text-decoration: none;
}

.ant-select-dropdown-menu-item-active {
    background-color: #eee !important;
}

.ant-select-dropdown-menu-item:hover {
    background-color: #eee !important;
}

.ant-select-dropdown {
    overflow: hidden;
}