.auth .ant-input-affix-wrapper .ant-input:not(:first-child) {
    padding-left: 35px;
}

.auth .ant-input-prefix i {
    color: rgba(0,0,0,.25)
}

.auth .login-button {
    height: 48px;
    display: flex;
}

/* Login Modal */

.login-modal .ant-modal-header {
    border: none;
    padding-top: 40px;
}

.login-modal .ant-modal-title {
    font-size: 30px;
}

.login-modal .ant-modal-body {
    padding: 0;
    padding-bottom: 10px;
}
